import React, { useState } from "react";
import "./App.css";
import "./input.css";
import Navbar from "./components/Navbar/Navbar.js";
import ContactModal from "./components/ContactModal/ContactModal.js";
import Welcome from "./components/Welcome/Welcome";
import About from "./components/About/About.js";
import ProjectsGrid from "./components/ProjectsGrid/ProjectsGrid.js";
import GameProjects from "./components/GameProjects/GameProjects";
import Footer from "./components/Footer/Footer.js";

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <Navbar />
      <div className="min-h-screen flex flex-col items-center justify-center">
        <div className="w-full p-4"></div>
        <div className="container mx-auto p-4 grid grid-cols-1 md:grid-cols-12 gap-4">
          <div id="home" className="col-span-1 md:col-start-2 md:col-span-10">
            <Welcome />
          </div>
          <div id="work" className="col-span-1 md:col-start-2 md:col-span-10">
            <ProjectsGrid />
            <GameProjects />
          </div>
          <div id="about" className="col-span-1 md:col-start-2 md:col-span-10">
            <About />
          </div>
        </div>
      </div>
      <Footer />
      <ContactModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default App;
