import React from "react";

const ContactModal = ({ isOpen, onClose }) => {

  return (
    <div>
      <div
        className={`fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 backdrop-blur flex ${
          !isOpen && "hidden"
        }`}
      >
        <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg">
          <span className="absolute top-0 right-0 p-4">
            <button
              onClick={onClose}
              className="text-3xl font-bold"
              aria-label="Close"
            >
              &times;
            </button>
          </span>

          <h2 className="text-2xl font-bold mb-4">Contact Me</h2>
          <p className="text-lg mb-4">
            Feel free to reach out to me via email or phone.
          </p>
          <p>
            <a href="mailto:chase@chasebrooke.dev" className="text-blue-500 hover:text-blue-700">
              chase@chasebrooke.dev
            </a>
          </p>
          <p>
            <a href="tel:+13096966981" className="text-blue-500 hover:text-blue-700">
              309-696-6981
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;
