import "./Footer.css";

import twitter from "../../img/twitterX.svg";
import github from "../../img/Github-Logo.svg";
import linkedin from "../../img/Linkedin-Logo.svg";

const Footer = () => {
  return (
    <div>
      <div className="flex items-center justify-center">
        <p>Find me on: </p>
      </div>

      <div className="footer-icons">
        <a href="https://twitter.com/aychase" target="_blank" rel="noreferrer">
          <img className="footer-icon" src={twitter} alt="twitter" />
        </a>
        <a
          href="https://github.com/speed-chaser"
          target="_blank"
          rel="noreferrer"
        >
          <img className="footer-icon" src={github} alt="GitHub" />
        </a>
        <a
          href="https://www.linkedin.com/in/chase-brooke-050a14179/"
          target="_blank"
          rel="noreferrer"
        >
          <img className="footer-icon" src={linkedin} alt="LinkedIn" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
