import React, { useState } from "react";
import "./Welcome.css";

import portraitSmall from "../../img/portrait-small.webp";
import portraitMedium from "../../img/portrait-medium.webp";
import portraitLarge from "../../img/portrait.webp";
import ContactModal from "../ContactModal/ContactModal";

const Welcome = () => {
  const scrollToSection = (sectionId) => {
    const sectionSelector = sectionId.startsWith("#")
      ? sectionId
      : `#${sectionId}`;
    const section = document.querySelector(sectionSelector);

    if (section) {
      // Set a different yOffset for mobile devices
      const mobileOffset = -290;
      const desktopOffset = -70;
      const yOffset = window.innerWidth <= 768 ? mobileOffset : desktopOffset; // 768px is a common breakpoint for mobile devices

      const y = section.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    } else {
      console.error(`No section found with ID ${sectionId}`);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <section className="bg-white text-gray-800 body-font">
      <div className="container mx-auto flex px-5 pb-12 sm:pt-4 flex-col lg:flex-row items-center">
        {/* Image Container */}
        <div className="w-full lg:w-1/2 mb-10 lg:mb-0 flex justify-center lg:justify-start">
          <div className="aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 bg-gray-200 rounded overflow-hidden">
            <picture>
              <source srcSet={portraitSmall} media="(max-width: 600px)" />
              <source srcSet={portraitMedium} media="(max-width: 1024px)" />
              <source srcSet={portraitLarge} media="(min-width: 1025px)" />
              <img
                className="object-cover object-center"
                alt="My Headshot"
                src={portraitLarge}
                loading="lazy"
              />
            </picture>
          </div>
        </div>

        {/* Text and Buttons Container */}
        <div className="flex-grow flex flex-col items-center lg:items-start xl:items-center text-center text-left lg:pl-12 xl:pl-24">
          <div className="text-center px-4 py-8">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900">
              Chase Brooke
            </h1>
            <p className="text-blue-600 text-xl md:text-2xl lg:text-3xl mt-2">
              Full-Stack Developer
            </p>
            <p className="mt-4 text-lg md:text-xl lg:text-2xl text-gray-700 italic xl:px-12">
              Innovative problem solver ready to create spectacular
              applications.
            </p>
          </div>

          <div className="flex flex-row justify-center lg:justify-start xl:justify-center space-x-4">
            <a
              href="#work"
              onClick={(e) => {
                e.preventDefault();
                scrollToSection("#work");
              }}
              className="button view-work-button"
            >
              View My Work
            </a>
            <button
              onClick={() => openModal()}
              className="button contact-me-button"
            >
              Contact Me
            </button>

            <ContactModal isOpen={isModalOpen} onClose={closeModal} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
