import pokedexImage from "../../img/pokedex-app.webp";
import movieApiImage from "../../img/movie-api-features.webp";
import movieFrontendImage from "../../img/chaseflix-app.webp";
import meetAppImage from "../../img/meet-app.webp";
import chatAppImage from "../../img/chat-app.webp";
import chaseflixAngular from "../../img/chaseflix-angular.webp";
import "./ProjectsGrid.css";

import React, { useState } from "react";
import Modal from "../Modal/Modal";
import ContactModal from "../ContactModal/ContactModal";

const projects = [
  {
    id: 1,
    title: "Pokedex App",
    imageUrl: pokedexImage,
    description: `A vanilla Javascript application connecting to an external Pokemon API and mapping 
      their information out into a grid the user can browse through similar to a Pokedex.`,
    technologies: ["Javascript", "HTML", "CSS", "Bootstrap"],
    liveUrl: "http://speed-chaser.github.io/pokedex-js-app",
    repoUrl: "http://github.com/speed-chaser/pokedex-js-app",
  },
  {
    id: 2,
    title: "Movie API",
    imageUrl: movieApiImage,
    description: `A complete backend system made using Node.js and MongoDB to allow users 
      to interact with a database of movies and create their own profile with 
      information stored in the database to interact with other users.`,
    technologies: ["Node.js", "MongoDB", "Mongoose", "Express"],
    liveUrl: "http://chaseflix.netlify.app",
    repoUrl: "http://github.com/speed-chaser/movie_api",
  },
  {
    id: 3,
    title: "Movie Frontend - React",
    imageUrl: movieFrontendImage,
    description: `The frontend made in conjunction with the Movie API project. 
    This was made using react and allows users to interact with the movie and user 
    databases to add movies to their favorites or look at other users favorites.`,
    technologies: ["React", "CSS"],
    liveUrl: "http://chaseflix.netlify.app",
    repoUrl: "http://github.com/speed-chaser/chaseflix-client",
  },
  {
    id: 4,
    title: "Meet App",
    imageUrl: meetAppImage,
    description: `A serverless react progressive web app that connects to events in
      a google api and can access them offline. This project was
      developed using a test-driven development technique. It also
      utilizes google OAUTH to sign in and use the application.`,
    technologies: [
      "React",
      "Jest",
      "Cucumber",
      "Puppeteer",
      "Atatus",
      "Rechards",
    ],
    liveUrl: "https://speed-chaser.github.io/meet-app",
    repoUrl: "http://github.com/speed-chaser/meet-app",
  },
  {
    id: 5,
    title: "Chat App",
    imageUrl: chatAppImage,
    description: `A React Native texting application that allows users to choose 
    a theme for their messages, send photos, videos, location, and text messages 
    to other users and access them offline.`,
    technologies: ["React Native", "Firebase"],
    liveUrl: "https://github.com/speed-chaser/chat-app#readme",
    repoUrl: "http://github.com/speed-chaser/chat-app",
  },
  {
    id: 6,
    title: "Movie Frontend - Angular",
    imageUrl: chaseflixAngular,
    description: `The front-end of my chaseflix movie project reimagined using the
    Angular Framework.`,
    technologies: ["Angular", "Angular Material", "TypeScript", "TypeDocs"],
    liveUrl: "https://speed-chaser.github.io/chaseflix-Angular-client",
    repoUrl: "http://github.com/speed-chaser/chaseflix-Angular-client",
  },
];

const ProjectCard = ({ project }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!modalOpen);

  return (
    <div className="flex flex-col max-w-sm rounded overflow-hidden shadow-lg h-full">
      <img
        className="w-full image cursor-pointer"
        src={project.imageUrl}
        alt={`Screenshot of ${project.title}`}
        onClick={toggleModal}
      />
      <Modal show={modalOpen} onClose={toggleModal}>
        <img
          className="w-full"
          src={project.imageUrl}
          alt={`Expanded screenshot of ${project.title}`}
          loading="lazy"
        />
      </Modal>
      <div className="flex-grow flex flex-col">
        <div className="px-6 py-4 flex-1 h-32">
          <div className="font-bold text-xl mb-2">{project.title}</div>
          <div className="text-gray-700 text-base overflow-hidden">
            {project.description}
          </div>
        </div>
        <div className="px-6 py-4">
          {project.technologies.map((tech, index) => (
            <span
              key={index}
              className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
            >
              {tech}
            </span>
          ))}
        </div>
      </div>
      <div className="px-6 pt-4 pb-2 mb-4">
        <a
          href={project.liveUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Live
        </a>
        <a
          href={project.repoUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded ml-4"
        >
          Code
        </a>
      </div>
    </div>
  );
};

const ProjectsGrid = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="container mx-auto my-5 px-4">
      <p className="text-4xl font-bold mb-4">My Web Projects:</p>
      <div className="grid gap-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 xs:justify-items-center">
        {projects.map((project) => (
          <div className="col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-1 xl:col-span-1 ">
            <ProjectCard key={project.id} project={project} />
          </div>
        ))}
        <div className="col-span-full sm:col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-3 2xl:col-span-3 flex justify-center">
          <div className="flex flex-col items-center justify-center border-2 border-dashed border-gray-300 bg-white rounded-lg p-4 shadow hover:shadow-md transition-all duration-300">
            <h3 className="text-xl font-bold mb-3">Your Project Next?</h3>
            <p className="text-gray-600 mb-6">
              Have an idea you're excited about? Let's make it a reality!
            </p>
            <button
              onClick={() => {
                openModal();
              }}
              className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 transition-all duration-300"
            >
              Contact Me
            </button>
          </div>
        </div>
        <ContactModal isOpen={isModalOpen} onClose={closeModal} />
      </div>
    </div>
  );
};

export default ProjectsGrid;
