import "./GameProjects.css";
import towerdefense from "../../img/tower-defense-game.webp";
import platformer from "../../img/platformer-game.webp";
import rocket from "../../img/rocket-game.webp";

import React, { useState } from "react";
import Modal from "../Modal/Modal";
import ContactModal from "../ContactModal/ContactModal";

const projects = [
  {
    id: "g1",
    title: "Physics Rocket Game",
    imageUrl: rocket,
    description: `You play as an astronaut trying to navigate through a set of extremely hazardous planets`,
    technologies: ["Unity", "C#"],
    liveUrl: "https://simmer.io/@speedchaser/rocket-brrr",
  },
  {
    id: "g2",
    title: "Tower Defense Game",
    imageUrl: towerdefense,
    description: `Your castle is being seiged and your princess is NOT in another castle. Use the units you can to keep the princess safe!`,
    technologies: ["Unity", "C#"],
    liveUrl: "https://simmer.io/@speedchaser/tower-defense",
  },
  {
    id: "g3",
    title: "Jump Man",
    imageUrl: platformer,
    description: `While exploring, you get stuck in a set of mysterious caves. Will you be able to find your way out?`,
    technologies: ["Unity", "C#"],
    liveUrl: "https://simmer.io/@speedchaser/jump-man",
  },
];

const ProjectCard = ({ project }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!modalOpen);

  return (
    <div className="flex flex-col max-w-sm rounded overflow-hidden shadow-lg h-full">
      <div className="aspect-ratio-4-3">
        <img
          className="w-full image cursor-pointer"
          src={project.imageUrl}
          alt={`Screenshot of ${project.title}`}
          onClick={toggleModal}
        />
      </div>
      <Modal show={modalOpen} onClose={toggleModal}>
        <img
          className="w-full"
          src={project.imageUrl}
          alt={`Expanded screenshot of ${project.title}`}
          loading="lazy"
        />
      </Modal>
      <div className="flex-grow flex flex-col">
        <div className="px-6 py-4 flex-1 h-32">
          <div className="font-bold text-xl mb-2">{project.title}</div>
          <div className="text-gray-700 text-base overflow-hidden">
            {project.description}
          </div>
        </div>
        <div className="px-6 py-4">
          {project.technologies.map((tech, index) => (
            <span
              key={index}
              className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
            >
              {tech}
            </span>
          ))}
        </div>
      </div>
      <div className="px-6 pt-4 pb-2 mb-4">
        <a
          href={project.liveUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Play
        </a>
      </div>
    </div>
  );
};
const GameProjects = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="container mx-auto my-5 px-4">
      <p className="text-4xl font-bold mb-4">My Game Projects:</p>
      <div className="grid gap-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 xs:justify-items-center">
        {projects.map((project) => (
          <div className="col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-1 xl:col-span-1 ">
            <ProjectCard key={project.id} project={project} />
          </div>
        ))}

        <ContactModal isOpen={isModalOpen} onClose={closeModal} />
      </div>
    </div>
  );
};

export default GameProjects;
